import AppsIcon from "@mui/icons-material/Apps";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import MUITextField from "@mui/material/TextField";
import jsonexport from "jsonexport/dist";
import { useState } from "react";
import { SelectField, useTranslate } from 'react-admin';
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  ImageField,
  ImageInput,
  Labeled,
  List,
  NumberField,
  NumberInput,
  SelectColumnsButton,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  WithRecord,
  downloadCSV,
  required,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh
} from "react-admin";
import { useWatch } from "react-hook-form";
import { Empty } from "../components/Empty";
import { VehicleListGrid } from "../components/VehicleList";
import { EditAction } from "../components/editAction";
import numberWithCommas from "../components/numberWithCommas";
import isGranted from "../services/security";

const exporter = (vehicles: any) => {
  const vehiclesForExport = vehicles.map((vehicle: any) => {
    const {
      "@id": arobasId,
      "@type": arobasType,
      administration,
      axle,
      brandParent,
      capacity,
      chassisNumber,
      equipment,
      fuel,
      gear,
      id,
      images,
      insurance,
      leasing,
      levelD,
      maintenanceType,
      operator,
      power,
      ptac,
      purchaseDate,
      pv,
      registrationDate,
      registrationNumberW,
      site,
      tank,
      timeInterval,
      tires,
      originId,
      updatedAt,
      cu,
      ...vehicleForExport
    } = vehicle; // omit backlinks and author
    return vehicleForExport;
  });
  jsonexport(
    vehiclesForExport,
    {
      headers: [
        "parkingNumber",
        "brand",
        "model",
        "registrationNumber",
        "type",
        "createdAt",
      ],
      rename: [
        "Numéro de parc",
        "Marque",
        "Modèle",
        "Immatriculation",
        "Type",
        "Date de création",
      ],
    },
    (err: any, csv: string) => {
      downloadCSV(csv, "Véhicules"); // download as 'posts.csv` file
    }
  );
};

const ReturnedCu = () => {
  const translate = useTranslate();
  const ptac = useWatch({ name: "ptac" });
  const pv = useWatch({ name: "pv" });
  const cu = !ptac || !pv ? 0 : numberWithCommas(ptac - pv);
  return (
    <MUITextField
      fullWidth
      label={translate('labels.CU')}
      InputProps={{
        readOnly: true,
      }}
      value={cu}
    />
  );
};

const ReturnedAge = () => {
  const translate = useTranslate();
  let registrationDate = useWatch({ name: "registrationDate" });
  registrationDate = new Date(registrationDate).getFullYear();
  const currentYear = new Date().getFullYear();
  // const cu = (!ptac) ? 0 : numberWithCommas(ptac - pv);
  return (
    <MUITextField
      fullWidth
      label={translate('titles.caracteristiques')}
      InputProps={{
        readOnly: true,
      }}
      value={!registrationDate ? 0 : currentYear - registrationDate}
    />
  );
};

const FieldWrapper = (choice: { children: any; label: string }) =>
  choice.children;

const VehicleListActions = ({ gridState, handleChangeGrid }: { gridState: any, handleChangeGrid: any }) => {
  const permissions = localStorage.getItem("permissions");

  return (
    <TopToolbar>
      <ButtonGroup
        sx={{
          "& .MuiButton-startIcon": {
            marginRight: "0px",
            marginLeft: "0px",
          },
        }}
        size="small">
        <Button
          variant={gridState === "table" ? "contained" : "outlined"}
          startIcon={<TableRowsIcon />}
          onClick={() => {
            handleChangeGrid("table")
          }}></Button>
        <Button
          variant={gridState !== "table" ? "contained" : "outlined"}
          startIcon={<AppsIcon />}
          onClick={() => {
            handleChangeGrid("grid")
          }}></Button>
      </ButtonGroup>
      {gridState === "table" && <SelectColumnsButton />}
      {isGranted("ROLE_MANAGER", permissions) && <CreateButton />}
      {gridState === "table" && <ExportButton />}
    </TopToolbar>
  );
};

const VehicleFilters = [
  <TextInput source={"query"} alwaysOn />,
  // <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

export function VehicleList(props: any) {
  // const gridState = localStorage.getItem("gridState");
  const [gridState2, setGridState2] = useState<string | undefined>(localStorage.getItem("gridState") || undefined)
  // const [gridState, setGridState] = useState("table");
  // localStorage.setItem("gridState", "table" );
  const handleChangeGrid = (e: string) => {
    setGridState2(e)
    localStorage.setItem("gridState", e);
  }

  const permissions = localStorage.getItem("permissions");

  const translate = useTranslate();

  return (
    <>
      {gridState2 === "grid" ? (
        <List
          actions={<VehicleListActions gridState={gridState2} handleChangeGrid={handleChangeGrid} />}
          filters={VehicleFilters}
          empty={<Empty create={false} />}
          exporter={exporter}
          emptyWhileLoading
          {...props}
          sx={{
            "& .RaList-main > .MuiCard-root": {
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
            },
          }}>
          <VehicleListGrid edit={isGranted("ROLE_MANAGER", permissions)} />
        </List>
      ) : (
        <List
          actions={<VehicleListActions gridState={gridState2} handleChangeGrid={handleChangeGrid} />}
          filters={VehicleFilters}
          empty={<Empty create={false} />}
          exporter={exporter}
          emptyWhileLoading
          {...props}>
          <DatagridConfigurable bulkActionButtons={false} rowClick="show">
            <TextField source={"parkingNumber"} />
            <TextField source={"gear"} />
            <TextField source={"chassisNumber"} />
            <TextField source={"brand"} label={"Marque"} />
            <SelectField source={"type"} /*label={"Type"}*/ choices={[
              { id: "Chargement arr", name: translate('labels.Chargement arr') },
              { id: "Deux roues", name: translate('labels.Deux roues') },
              { id: "Quatre Roues", name: translate('labels.Quatre Roues') },
              { id: "Plateau", name: translate('labels.Plateau') },
              { id: "Mécanique", name: translate('labels.Mécanique') },
              { id: "Aspiratrice", name: translate('labels.Aspiratrice') },
              { id: "Sur pneus", name: translate('labels.Sur pneus') },
              { id: "Bras coulissante", name: translate('labels.Bras coulissante') },
              { id: "Citerne", name: translate('labels.Citerne') },
              { id: "Voiries", name: translate('labels.Voiries') },
              { id: "Basculante", name: translate('labels.Basculante') },
              { id: "sur berce AMPL", name: translate('labels.sur berce AMPL') },
              { id: "Champignon", name: translate('labels.Champignon') },
              { id: "Double Crochet", name: translate('labels.Double Crochet') },
              { id: "Hydraulique", name: translate('labels.Hydraulique') },
              { id: "VL", name: translate('labels.VL') },
            ]} />
            <TextField
              source={"registrationNumber"}
            />
            <NumberField
              source={"ptac"}
            />
            <TextField
              source={"registrationNumberW"}
            />
            <NumberField
              source={"power"}
            />
        
            {/* <NumberField source={"currentTimeMeter"} label={"Heures moteur"} />
            <NumberField source={"currentMileage"} label={"Kilométrage"} /> */}

            {isGranted("ROLE_MANAGER", permissions) && (
              <FieldWrapper label="Actions">
                <EditButton />
                &nbsp;
                <DeleteWithConfirmButton />
              </FieldWrapper>
            )}
          </DatagridConfigurable>
        </List>
      )}
    </>
  );
}

export function VehicleCreate(props: any) {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [maintenanceType, setMaintenanceType] = useState("");
  const onSuccess = () => {
    notify(`L'élément a bien été créé`);
    redirect("/vehicles/");
    refresh();
  };
  return (
    <Create
      title={translate('titles.addvehicle')}
      mutationOptions={{ onSuccess }}
      {...props}>
      <SimpleForm justifyContent="center">
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.caracteristiques')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <TextInput
            source={"parkingNumber"}
            validate={[required("Champ obligatoire")]}
          //label={"parkingNumber"}
          />
          <AutocompleteInput
            source={"gear"}
            validate={[required("Champ obligatoire")]}
            choices={[
              { id: "BOM", name: translate('labels.BOM') },
              { id: "BOM+Grue", name: translate('labels.BOM+Grue') },
              { id: "Benne Satellite", name: translate('labels.Benne Satellite') },
              { id: "BTP", name: translate('labels.BTP') },
              { id: "BTP avec grappin", name: translate('labels.BTP avec grappin') },
              { id: "AMPL", name: translate('labels.AMPL') },
              { id: "Mini AMPL", name: translate('labels.Mini AMPL') },
              { id: "Balayeuse CEKSAN", name: translate('labels.Balayeuse CEKSAN') },
              { id: "Citerne laveuse", name: translate('labels.Citerne laveuse') },
              { id: "Balayeuse", name: translate('labels.Balayeuse') },
              { id: "Laveuse automatique", name: translate('labels.Laveuse automatique') },
              { id: "Laveuse avec grue", name: translate('labels.Laveuse avec grue') },
              { id: "Chargeur sur roue", name: translate('labels.Chargeur sur roue') },
              { id: "JCB", name: translate('labels.JCB') },
              { id: "Tracteur", name: translate('labels.Tracteur') },
              { id: "Aspirateur", name: translate('labels.Aspirateur') },
              { id: "Tricycle électrique", name: translate('labels.Tricycle électrique') },
              { id: "Motocycle", name: translate('labels.Motocycle') },
              { id: "Véhicule de service", name: translate('labels.Véhicule de service') },
              { id: "Véhicule utilitaire", name: translate('labels.Véhicule utilitaire') },
              { id: "Atelier mobile", name: translate('labels.Atelier mobile') },
              { id: "Mini-Laveuse", name: translate('labels.Mini-Laveuse') },
              { id: "AMPL+GRUE", name: translate('labels.AMPL+GRUE') },
              { id: "Atelier mobile", name: translate('labels.Atelier mobile') },
              { id: "Benne", name: translate('labels.Benne') },
              { id: "Broyeur", name: translate('labels.Broyeur') },
              { id: "Chargeuse", name: translate('labels.Chargeuse') },
              { id: "Compacteur", name: translate('labels.Compacteur') },
              { id: "Camion", name: translate('labels.Camion') },
              { id: "Cribleuse", name: translate('labels.Cribleuse') },
              { id: "Liaison", name: translate('labels.Liaison') },
              { id: "Mobylette", name: translate('labels.Mobylette') },
              { id: "Multibenne", name: translate('labels.Multibenne') },
              { id: "Tractopelle", name: translate('labels.Tractopelle') },
              { id: "Tricycle", name: translate('labels.Tricycle') },
              { id: "Bac plastique", name: translate('labels.Bac plastique') },
              { id: "BAV", name: translate('labels.BAV') },
              { id: "Plateforme", name: translate('labels.Plateforme') },
              { id: "Corbeille", name: translate('labels.Corbeille') },
              { id: "Laveuse", name: translate('labels.Laveuse') },
              { id: "Mini camion BOM de 3,5T", name: translate('labels.Mini camion BOM de 3,5T') },
              { id: "Mini camion BOM de 7,5T", name: translate('labels.Mini camion BOM de 7,5T') },
              { id: "Camion BOM de 12T", name: translate('labels.Camion BOM de 12T') },
              { id: "Camion BOM de 19T", name: translate('labels.Camion BOM de 19T') },
              { id: "Camion BOM de 26T", name: translate('labels.Camion BOM de 26T') },
              { id: "Camion Ampliroll 19T grue & grappin", name: translate('labels.Camion Ampliroll 19T grue & grappin') },
              { id: "Camion Ampliroll 26T grue & grappin", name: translate('labels.Camion Ampliroll 26T grue & grappin') },
              { id: "Mini Camion 7,5T grue & grappin", name: translate('labels.Mini Camion 7,5T grue & grappin') },
              { id: "Mini Camion 9,5T grue & grappin", name: translate('labels.Mini Camion 9,5T grue & grappin') },
              { id: "Camion Plateau 3,5 T", name: translate('labels.Camion Plateau 3,5 T') },
              { id: "Benne Grue 7,5T", name: "Benne Grue 7,5T" },
              { id: "Benne Grue 9,5T", name: "Benne Grue 9,5T" },
              { id: "Benne Grue 19T", name: "Benne Grue 19T" },
              { id: "Benne Grue 26T", name: "Benne Grue 26T" }
            ]}
          />
          <TextInput
            source={"registrationNumber"}
            //label="Matricule"
            validate={[required("Champ obligatoire")]}
          />
          <TextInput
            source={"chassisNumber"}
            //label="N° chassis"
            validate={[required("Champ obligatoire")]}
          />
          <AutocompleteInput
            source={"type"}
            //label="Type"
            choices={[
              { id: "Chargement arr", name: translate('labels.Chargement arr') },
              { id: "Deux roues", name: translate('labels.Deux roues') },
              { id: "Quatre Roues", name: translate('labels.Quatre Roues') },
              { id: "Plateau", name: translate('labels.Plateau') },
              { id: "Mécanique", name: translate('labels.Mécanique') },
              { id: "Aspiratrice", name: translate('labels.Aspiratrice') },
              { id: "Sur pneus", name: translate('labels.Sur pneus') },
              { id: "Sur chaine", name: "Sur chaine" },
              { id: "Bras coulissante", name: translate('labels.Bras coulissante') },
              { id: "Citerne", name: translate('labels.Citerne') },
              { id: "Voiries", name: translate('labels.Voiries') },
              { id: "Basculante", name: translate('labels.Basculante') },
              { id: "sur berce AMPL", name: translate('labels.sur berce AMPL') },
              { id: "Champignon", name: translate('labels.Champignon') },
              { id: "Double Crochet", name: translate('labels.Double Crochet') },
              { id: "Hydraulique", name: translate('labels.Hydraulique') },
              { id: "VL", name: translate('labels.VL') },
            ]}
          />
          <AutocompleteInput
            source={"brand"}
            //label="Marque"
            choices={[
              { id: "sinotruk", name: "Sinotruk" },
              { id: "renault", name: "Renault" },
              { id: "Volvo", name: "Volvo" },
              { id: "Mercedes", name: "Mercedes" },
              { id: "Iveco", name: "Iveco" },
              { id: "Mitsubishi", name: "Mitsubishi" },
              { id: "scania", name: "Scania" },
              { id: "baw", name: "BAW" },
              { id: "isuzu", name: "Isuzu" },
              { id: "piquersa", name: "Piquersa" },
            ]}
          />
          <TextInput source={"model"} /*label="Modèle"*/ />
          <SelectInput
            source={"levelD"}
            //label="Niveau de dépollution"
            choices={[
              { id: "Euro 2", name: "Euro 2" },
              { id: "Euro 3", name: "Euro 3" },
              { id: "Euro 4", name: "Euro 4" },
              { id: "Euro 5", name: "Euro 5" },
              { id: "Euro 6", name: "Euro 6" },
            ]}
          />

          <TextInput source={"registrationNumberW"} /*label="Matricule WW"*/ />
          <SelectInput
            source={"fuel"}
            //label="Carburant"
            choices={[
              { id: "Gasoil", name: translate('labels.Gasoil') },
              { id: "Essence", name: translate('labels.Essence') },
              { id: "Electrique", name: translate('labels.Electrique') },
            ]}
          />
          <DateInput
            source={"registrationDate"}
          //label="Date d'immatriculation"
          />
          <DateInput source={"purchaseDate"} /*label="Date d'achat"*/ />
          <ReturnedAge />
          <TextInput source={"brandParent"} /*label="E-marque"*/ />
          <NumberInput source={"ptac"} /*label="PTAC (Kg)"*/ />
          <NumberInput source={"pv"} /*label="PV (Kg)"*/ />
          <ReturnedCu />
          <SelectInput
            source={"axle"}
            //label="Essieu"
            choices={[
              { id: "6*2x4", name: "6*2x4" },
              { id: "6x4", name: "6x4" },
              { id: "4x2", name: "4x2" },
              { id: "8x4", name: "8x4" },
              { id: "4x4", name: "4x4" },
            ]}
          />
          <TextInput source={"tires"} /*label="Pneus"*/ />
          <NumberInput source={"tank"} /*label="Réservoir carburant (L)"*/ />
          <NumberInput source={"power"} /*label="Puissance fiscale"*/ />

          <NumberInput source={"capacity"} /*label="Capacité (m³)"*/ />
        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.situationAdministrative')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <TextInput source={"administration.owner"} /*label="Propriétaire"*/ />
          <SelectInput
            source={"administration.contract"}
            //label="Contrat"
            choices={[
              { id: "PAP Saint Joseph/ Saint Philippe", name: "PAP Saint Joseph/ Saint Philippe" },
              { id: "PAP TAMPON / Entre Deux", name: "PAP TAMPON / Entre Deux" },
              { id: "Plate-forme Ravine des Gregues", name: "Plate-forme Ravine des Gregues" },
              { id: "Plate-forme Plaine des cafres", name: "Plate-forme Plaine des cafres" },
              { id: "Haut de QUAI", name: "Haut de QUAI" },
              { id: "Bas de QUAI", name: "Bas de QUAI" },
              { id: "BAV (Maintenance/Entretien)", name: "BAV (Maintenance/Entretien)" },
              { id: "BAV (Vidage)", name: "BAV (Vidage)" },
              { id: "ZAP (Zone d'apport volontaire Bois court)", name: "ZAP (Zone d'apport volontaire Bois court)" },
              { id: "Actité Annexe", name: "Actité Annexe" },
              { id: "casablanca", name: "Casablanca" },
              { id: "marrakech", name: "Marrakech" },
              { id: "oujda", name: "Oujda" },
              { id: "tanger", name: "Tanger" },
              { id: "rabat", name: "Rabat" },
              { id: "fes", name: "Fes" },
            ]}
          />
          <TextInput source={"administration.operation"} /*label="Exploitation"*/ />
          <SelectInput
            source={"administration.contractStatus"}
            //label="Type de contrat"
            choices={[
              { id: "contractuel", name: translate('labels.Contractuel') },
              { id: "nonContractuel", name: translate('labels.NonContractuel') },
              { id: "rabat", name: "Rabat" },
              { id: "fes", name: "Fes" },
            ]}
          />
          <TextInput
            source={"administration.affectation"}
          //label="Affectation analytique"
          />
          <DateInput
            source={"administration.date"}
          //label="Date de mise en service"
          />
          <SelectInput
            source={"administration.status"}
            //label="Statut"
            choices={[
              { id: "Actif", name: translate('labels.Actif') },
              { id: "Inactif", name: translate('labels.Inactif') },
            ]}
          />
        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.equipementEmbarque')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <TextInput
            source={"equipment.washingEquipment"}
          //label="Équipement de levage"
          />
          <TextInput
            source={"equipment.towingHitch"}
          //label="Crochet d'atellage"
          />
          <BooleanInput source={"equipment.gps"} /*label="GPS"*/ />
        </Box>

        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.financement')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <TextInput
            source={"leasing.contractNumber"}
          //label="N° de contrat leasing"
          />
          <TextInput source={"leasing.organization"} /*label="Organisme"*/ />
          <NumberInput
            source={"leasing.taxFreeMonthlyPayment"}
          //label="Mensualité HT"
          />
          <NumberInput source={"leasing.duration"} /*label="Durée"*/ />
          <DateInput
            source={"leasing.contractStartDate"}
          //label="Début de contrat"
          />
          <DateInput
            source={"leasing.contractEndDate"}
          //label="Fin de contrat"
          />
          <NumberInput source={"leasing.vr"} /*label="Valeur résiduelle"*/ />
          <NumberInput
            source={"leasing.acquisitionPrice"}
          //label="Prix d'acquisition"
          />
          <NumberInput
            source={"leasing.contractAmount"}
          //label="Montant du contrat"
          />
          <TextInput source={"leasing.status"} /*label="État du contrat"*/ />
        </Box>

        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.assurance')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateInput source={"insurance.date"} /*label="Date"*/ />
          <NumberInput
            source={"insurance.totalPlacesAllowed"}
          //label="Nombre de places autorisées"
          />
          <NumberInput
            source={"insurance.totalSeats"}
          //label="Nombre de sièges"
          />
          <TextInput source={"insurance.usage"} /*label="Usage"*/ />
          <NumberInput
            source={"insurance.taxHorsePower"}
          //label="Puissance fiscale (CV)"
          />
          <NumberInput source={"insurance.der"} /*label="DER"*/ />
          <NumberInput
            source={"insurance.damageValue"}
          //label="Valeur dommage"
          />
          <NumberInput source={"insurance.fireValue"} /*label="Valeur incendie"*/ />
          <NumberInput
            source={"insurance.brokenWindowValue"}
          //label="Valeur brise glace"
          />
          <NumberInput source={"insurance.rcBonus"} /*label="Prime RC"*/ />
          <NumberInput source={"insurance.damageBonus"} /*label="Prime dommage"*/ />
          <NumberInput source={"insurance.fireBonus"} /*label="Prime incendie"*/ />
          <NumberInput source={"insurance.theftBonus"} /*label="Prime vol"*/ />
          <NumberInput
            source={"insurance.brokenWindowBonus"}
          //label="Prime brise glace"
          />
          <NumberInput source={"insurance.totalValue"} /*label="Total (Net)"*/ />
        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          Compteur horaire et kilométrique
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <NumberInput source="currentTimeMeter" label="Heures moteur" />
          <NumberInput source="currentMileage" label="Kilométrage" />
        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.maintenanceVehicule')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <SelectInput
            source={"maintenanceType"}
            //label="Type de maintenance"
            choices={[
              { id: "mileage", name: translate('labels.Kilométrique') },
              { id: "time", name: translate('labels.Horaire') },
            ]}
            onChange={(e) => setMaintenanceType(e.target.value)}
          />
          {maintenanceType === "mileage" && (
            <>
              <NumberInput
                source={"lastMileage"}
              //label="Kilométrage de la dernière visite"
              />
              <NumberInput
                source={"mileageInterval"}
              //label="Intervale entre les visites (Km)"
              />
            </>
          )}
          {maintenanceType === "time" && (
            <>
              <NumberInput
                source={"lastTimeMeter"}
              //label="Compteur horaire de la dernière visite"
              />
              <NumberInput
                source={"timeInterval"}
              //label="Intervale entre les visites (heures)"
              />
            </>
          )}
        </Box>
        <ImageInput source="images" /*label="Images"*/ accept="image/*" multiple>
          <ImageField source="src" />
        </ImageInput>

        {/* <TextInput source={"images"} />
      <TextInput source={"site"} /> */}
      </SimpleForm>
    </Create>
  );
}

const VehicleTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <span>
      {record.parkingNumber} - {record.brand} {record.model}
    </span>
  );
};

export const VehicleEdit: React.FC = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [maintenanceType, setMaintenanceType] = useState(
    useRecordContext()?.maintenanceType
  );

  return (
    <Edit title={<VehicleTitle />} mutationMode="pessimistic" {...props}>
      <SimpleForm justifyContent="center">
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.caracteristiques')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <TextInput
            source={"parkingNumber"}
            validate={[required("Champ obligatoire")]}
          //label="N° de parc"
          />
          <AutocompleteInput
            source={"gear"}
            choices={[
              { id: "BOM", name: translate('labels.BOM') },
              { id: "BOM+Grue", name: translate('labels.BOM+Grue') },
              { id: "Benne Satellite", name: translate('labels.Benne Satellite') },
              { id: "BTP", name: translate('labels.BTP') },
              { id: "BTP avec grappin", name: translate('labels.BTP avec grappin') },
              { id: "AMPL", name: translate('labels.AMPL') },
              { id: "Mini AMPL", name: translate('labels.Mini AMPL') },
              { id: "Balayeuse CEKSAN", name: translate('labels.Balayeuse CEKSAN') },
              { id: "Citerne laveuse", name: translate('labels.Citerne laveuse') },
              { id: "Balayeuse", name: translate('labels.Balayeuse') },
              { id: "Laveuse automatique", name: translate('labels.Laveuse automatique') },
              { id: "Laveuse avec grue", name: translate('labels.Laveuse avec grue') },
              { id: "Chargeur sur roue", name: translate('labels.Chargeur sur roue') },
              { id: "JCB", name: translate('labels.JCB') },
              { id: "Tracteur", name: translate('labels.Tracteur') },
              { id: "Aspirateur", name: translate('labels.Aspirateur') },
              { id: "Tricycle électrique", name: translate('labels.Tricycle électrique') },
              { id: "Motocycle", name: translate('labels.Motocycle') },
              { id: "Véhicule de service", name: translate('labels.Véhicule de service') },
              { id: "Véhicule utilitaire", name: translate('labels.Véhicule utilitaire') },
              { id: "Atelier mobile", name: translate('labels.Atelier mobile') },
              { id: "Mini-Laveuse", name: translate('labels.Mini-Laveuse') },
              { id: "AMPL+GRUE", name: translate('labels.AMPL+GRUE') },
              { id: "Atelier mobile", name: translate('labels.Atelier mobile') },
              { id: "Benne", name: translate('labels.Benne') },
              { id: "Broyeur", name: translate('labels.Broyeur') },
              { id: "Chargeuse", name: translate('labels.Chargeuse') },
              { id: "Compacteur", name: translate('labels.Compacteur') },
              { id: "Camion", name: translate('labels.Camion') },
              { id: "Cribleuse", name: translate('labels.Cribleuse') },
              { id: "Liaison", name: translate('labels.Liaison') },
              { id: "Mobylette", name: translate('labels.Mobylette') },
              { id: "Multibenne", name: translate('labels.Multibenne') },
              { id: "Tractopelle", name: translate('labels.Tractopelle') },
              { id: "Tricycle", name: translate('labels.Tricycle') },
              { id: "Bac plastique", name: translate('labels.Bac plastique') },
              { id: "BAV", name: translate('labels.BAV') },
              { id: "Plateforme", name: translate('labels.Plateforme') },
              { id: "Corbeille", name: translate('labels.Corbeille') },
              { id: "Laveuse", name: translate('labels.Laveuse') },
              { id: "Mini camion BOM de 3,5T", name: translate('labels.Mini camion BOM de 3,5T') },
              { id: "Mini camion BOM de 7,5T", name: translate('labels.Mini camion BOM de 7,5T') },
              { id: "Camion BOM de 12T", name: translate('labels.Camion BOM de 12T') },
              { id: "Camion BOM de 19T", name: translate('labels.Camion BOM de 19T') },
              { id: "Camion BOM de 26T", name: translate('labels.Camion BOM de 26T') },
              { id: "Camion Ampliroll 19T grue & grappin", name: translate('labels.Camion Ampliroll 19T grue & grappin') },
              { id: "Camion Ampliroll 26T grue & grappin", name: translate('labels.Camion Ampliroll 26T grue & grappin') },
              { id: "Mini Camion 7,5T grue & grappin", name: translate('labels.Mini Camion 7,5T grue & grappin') },
              { id: "Mini Camion 9,5T grue & grappin", name: translate('labels.Mini Camion 9,5T grue & grappin') },
              { id: "Camion Plateau 3,5 T", name: translate('labels.Camion Plateau 3,5 T') },
              { id: "Benne Grue 7,5T", name: "Benne Grue 7,5T" },
              { id: "Benne Grue 9,5T", name: "Benne Grue 9,5T" },
              { id: "Benne Grue 19T", name: "Benne Grue 19T" },
              { id: "Benne Grue 26T", name: "Benne Grue 26T" }
            ]}
          />
          <TextInput
            source={"registrationNumber"}
            //label="Matricule"
            validate={[required("Champ obligatoire")]}
          />
          <TextInput
            source={"chassisNumber"}
            //label="N° chassis"
            validate={[required("Champ obligatoire")]}
          />
          <AutocompleteInput
            source={"type"}
            //label="Type"
            choices={[
              { id: "Chargement arr", name: translate('labels.Chargement arr') },
              { id: "Deux roues", name: translate('labels.Deux roues') },
              { id: "Quatre Roues", name: translate('labels.Quatre Roues') },
              { id: "Plateau", name: translate('labels.Plateau') },
              { id: "Mécanique", name: translate('labels.Mécanique') },
              { id: "Aspiratrice", name: translate('labels.Aspiratrice') },
              { id: "Sur pneus", name: translate('labels.Sur pneus') },
              { id: "Sur chaine", name: "Sur chaine" },
              { id: "Bras coulissante", name: translate('labels.Bras coulissante') },
              { id: "Citerne", name: translate('labels.Citerne') },
              { id: "Voiries", name: translate('labels.Voiries') },
              { id: "Basculante", name: translate('labels.Basculante') },
              { id: "sur berce AMPL", name: translate('labels.sur berce AMPL') },
              { id: "Champignon", name: translate('labels.Champignon') },
              { id: "Double Crochet", name: translate('labels.Double Crochet') },
              { id: "Hydraulique", name: translate('labels.Hydraulique') },
              { id: "VL", name: translate('labels.VL') },
            ]}
          />
          <AutocompleteInput
            source={"brand"}
            //label="Marque"
            choices={[
              { id: "sinotruk", name: "Sinotruk" },
              { id: "renault", name: "Renault" },
              { id: "Volvo", name: "Volvo" },
              { id: "Mercedes", name: "Mercedes" },
              { id: "Iveco", name: "Iveco" },
              { id: "Mitsubishi", name: "Mitsubishi" },
              { id: "scania", name: "Scania" },
              { id: "baw", name: "BAW" },
              { id: "isuzu", name: "Isuzu" },
              { id: "piquersa", name: "Piquersa" },
            ]}
          />
          <TextInput source={"model"} /*label="Modèle"*/ />
          <SelectInput
            source={"levelD"}
            //label="Niveau de dépollution"
            choices={[
              { id: "Euro 2", name: "Euro 2" },
              { id: "Euro 3", name: "Euro 3" },
              { id: "Euro 4", name: "Euro 4" },
              { id: "Euro 5", name: "Euro 5" },
              { id: "Euro 6", name: "Euro 6" },
            ]}
          />

          <TextInput source={"registrationNumberW"} /*label="Matricule WW"*/ />
          <SelectInput
            source={"fuel"}
            //label="Carburant"
            choices={[
              { id: "Gasoil", name: translate('labels.Gasoil') },
              { id: "Essence", name: translate('labels.Essence') },
              { id: "Electrique", name: translate('labels.Electrique') },
            ]}
          />
          <DateInput
            source={"registrationDate"}
          //label="Date d'immatriculation"
          />
          <DateInput source={"purchaseDate"} /*label="Date d'achat"*/ />
          <TextInput source={"brandParent"} /*label="E-marque"*/ />
          <NumberInput source={"ptac"} /*label="PTAC (Kg)"*/ />
          <NumberInput source={"pv"} /*label="PV (Kg)"*/ />
          <ReturnedCu />
          {/* <NumberInput source={"cu"} label="CU" /> */}
          <SelectInput
            source={"axle"}
            //label="Essieu"
            choices={[
              { id: "6*2x4", name: "6*2x4" },
              { id: "6x4", name: "6x4" },
              { id: "4x2", name: "4x2" },
              { id: "8x4", name: "8x4" },
              { id: "4x4", name: "4x4" },
            ]}
          />
          <TextInput source={"tires"} /*label="Pneus"*/ />
          <NumberInput source={"tank"} /*label="Réservoir carburant (L)"*/ />
          <NumberInput source={"power"} /*label="Puissance fiscale"*/ />

          <NumberInput source={"capacity"} /*label="Capacité (m³)"*/ />
        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.situationAdministrative')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <TextInput source={"administration.owner"} /*label="Propriétaire"*/ />
          <SelectInput
            source={"administration.contract"}
            //label="Contrat"
            choices={[
              { id: "PAP Saint Joseph/ Saint Philippe", name: "PAP Saint Joseph/ Saint Philippe" },
              { id: "PAP TAMPON / Entre Deux", name: "PAP TAMPON / Entre Deux" },
              { id: "Plate-forme Ravine des Gregues", name: "Plate-forme Ravine des Gregues" },
              { id: "Plate-forme Plaine des cafres", name: "Plate-forme Plaine des cafres" },
              { id: "Haut de QUAI", name: "Haut de QUAI" },
              { id: "Bas de QUAI", name: "Bas de QUAI" },
              { id: "BAV (Maintenance/Entretien)", name: "BAV (Maintenance/Entretien)" },
              { id: "BAV (Vidage)", name: "BAV (Vidage)" },
              { id: "ZAP (Zone d'apport volontaire Bois court)", name: "ZAP (Zone d'apport volontaire Bois court)" },
              { id: "Actité Annexe", name: "Actité Annexe" },
              { id: "casablanca", name: "Casablanca" },
              { id: "marrakech", name: "Marrakech" },
              { id: "oujda", name: "Oujda" },
              { id: "tanger", name: "Tanger" },
              { id: "rabat", name: "Rabat" },
              { id: "fes", name: "Fes" },
            ]}
          />
          <TextInput source={"administration.operation"} /*label="Exploitation"*/ />
          <SelectInput
            source={"administration.contractStatus"}
            //label="Type de contrat"
            choices={[
              { id: "contractuel", name: translate('labels.Contractuel') },
              { id: "nonContractuel", name: translate('labels.NonContractuel') },
              { id: "rabat", name: "Rabat" },
              { id: "fes", name: "Fes" },
            ]}
          />
          <TextInput
            source={"administration.affectation"}
          //label="Affectation analytique"
          />
          <DateInput
            source={"administration.date"}
          //label="Date de mise en service"
          />
          <SelectInput
            source={"administration.status"}
            //label="Statut"
            choices={[
              { id: "Actif", name: translate('labels.Actif') },
              { id: "Inactif", name: translate('labels.Inactif') },
            ]}
          />
        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.equipementEmbarque')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <TextInput
            source={"equipment.washingEquipment"}
          //label="Équipement de levage"
          />
          <TextInput
            source={"equipment.towingHitch"}
          //label="Crochet d'atellage"
          />
          <BooleanInput source={"equipment.gps"} /*label="GPS"*/ />
        </Box>

        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.financement')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <TextInput
            source={"leasing.contractNumber"}
          //label="N° de contrat leasing"
          />
          <TextInput source={"leasing.organization"} /*label="Organisme"*/ />
          <NumberInput
            source={"leasing.taxFreeMonthlyPayment"}
          //label="Mensualité HT"
          />
          <NumberInput source={"leasing.duration"} /*label="Durée"*/ />
          <DateInput
            source={"leasing.contractStartDate"}
          //label="Début de contrat"
          />
          <DateInput
            source={"leasing.contractEndDate"}
          //label="Fin de contrat"
          />
          <NumberInput source={"leasing.vr"} /*label="Valeur résiduelle"*/ />
          <NumberInput
            source={"leasing.acquisitionPrice"}
          //label="Prix d'acquisition"
          />
          <NumberInput
            source={"leasing.contractAmount"}
          //label="Montant du contrat"
          />
          <TextInput source={"leasing.status"} /*label="État du contrat"*/ />
        </Box>

        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.assurance')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <DateInput source={"insurance.date"} /*label="Date"*/ />
          <NumberInput
            source={"insurance.totalPlacesAllowed"}
          //label="Nombre de places autorisées"
          />
          <NumberInput
            source={"insurance.totalSeats"}
          //label="Nombre de sièges"
          />
          <TextInput source={"insurance.usage"} /*label="Usage"*/ />
          <NumberInput
            source={"insurance.taxHorsePower"}
          //label="Puissance fiscale (CV)"
          />
          <NumberInput source={"insurance.der"} /*label="DER"*/ />
          <NumberInput
            source={"insurance.damageValue"}
          //label="Valeur dommage"
          />
          <NumberInput source={"insurance.fireValue"} /*label="Valeur incendie"*/ />
          <NumberInput
            source={"insurance.brokenWindowValue"}
          //label="Valeur brise glace"
          />
          <NumberInput source={"insurance.rcBonus"} /*label="Prime RC"*/ />
          <NumberInput source={"insurance.damageBonus"} /*label="Prime dommage"*/ />
          <NumberInput source={"insurance.fireBonus"} /*label="Prime incendie"*/ />
          <NumberInput source={"insurance.theftBonus"} /*label="Prime vol"*/ />
          <NumberInput
            source={"insurance.brokenWindowBonus"}
          //label="Prime brise glace"
          />
          <NumberInput source={"insurance.totalValue"} /*label="Total (Net)"*/ />
        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          Compteur horaire et kilométrique
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <NumberInput source="currentTimeMeter" label="Heures moteur" />
          <NumberInput source="currentMileage" label="Kilométrage" />
        </Box>
        <Typography variant="h6" fontWeight="fontWeightBold" mb={2}>
          {translate('titles.maintenanceVehicule')}
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(5, 1fr)",
          }}
          gap={2}
          width="100%">
          <SelectInput
            source={"maintenanceType"}
            //label="Type de maintenance"
            choices={[
              { id: "mileage", name: translate('labels.Kilométrique') },
              { id: "time", name: translate('labels.Horaire') },
            ]}
            onChange={(e) => setMaintenanceType(e.target.value)}
          />
          {maintenanceType === "mileage" && (
            <>
              <NumberInput
                source={"lastMileage"}
              //label="Kilométrage de la dernière visite"
              />
              <NumberInput
                source={"mileageInterval"}
              //label="Intervale entre les visites (Km)"
              />
            </>
          )}
          {maintenanceType === "time" && (
            <>
              <NumberInput
                source={"lastTimeMeter"}
              //label="Compteur horaire de la dernière visite"
              />
              <NumberInput
                source={"timeInterval"}
              //label="Intervale entre les visites (heures)"
              />
            </>
          )}
        </Box>
        <ImageInput source="images" /*label="Images"*/ accept="image/*" multiple>
          <ImageField source="src" />
        </ImageInput>
        {/* <TextInput source={"images"} />
      <TextInput source={"site"} /> */}
      </SimpleForm>
    </Edit>
  );
};

export const VehicleShow: React.FC = (props) => {
  const maintenanceType = useRecordContext()?.maintenanceType;
  return (
    <Show
      title={<VehicleTitle />}
      actions={<EditAction />}
      {...props}
      sx={{ fontSize: "20px" }}>
      <TabbedShowLayout syncWithLocation={false}>
        <TabbedShowLayout.Tab label="Technique">
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
              xl: "repeat(5, 1fr)",
            }}
            gap={2}
            width="100%">
            <Labeled label="N° de parc">
              <TextField
                //label="N° de parc"
                source={"parkingNumber"}
                fontWeight="bold"
              />
            </Labeled>
            <Labeled>
              <TextField fontWeight="bold" source={"gear"} />
            </Labeled>
            <Labeled label="Matricule">
              <TextField
                fontWeight="bold"
                source={"registrationNumber"}
              //label="Matricule"
              />
            </Labeled>
            <Labeled label="N° chassis">
              <TextField
                fontWeight="bold"
                source={"chassisNumber"}
              //label="N° chassis"
              />
            </Labeled>
            <Labeled label="Type">
              <TextField fontWeight="bold" source={"type"} /*label="Type"*/ />
            </Labeled>
            <Labeled label="Marque">
              <TextField fontWeight="bold" source={"brand"} /*label="Marque"*/ />
            </Labeled>
            <Labeled label="Modèle">
              <TextField fontWeight="bold" source={"model"} /*label="Modèle"*/ />
            </Labeled>
            <Labeled label="Niveau de dépollution">
              <TextField
                fontWeight="bold"
                source={"levelD"}
              //label="Niveau de dépollution"
              />
            </Labeled>
            <Labeled label="Matricule WW">
              <TextField
                fontWeight="bold"
                source={"registrationNumberW"}
              //label="Matricule WW"
              />
            </Labeled>
            <Labeled label="Carburant">
              <TextField fontWeight="bold" source={"fuel"} /*label="Carburant"*/ />
            </Labeled>
            <Labeled label="Date d'immatriculation">
              <DateField
                locales="fr-FR"
                fontWeight="bold"
                source={"registrationDate"}
              //label="Date d'immatriculation"
              />
            </Labeled>
            <Labeled label="Date d'achat">
              <DateField
                locales="fr-FR"
                fontWeight="bold"
                source={"purchaseDate"}
              //label="Date d'achat"
              />
            </Labeled>
            <SimpleShowLayout sx={{ padding: 0, fontSize: "20px" }}>
              <WithRecord
                //label="Age du véhicule"
                render={(record) => (
                  <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                    {!record.registrationDate
                      ? " "
                      : new Date().getFullYear() -
                      new Date(record.registrationDate).getFullYear()}
                  </span>
                )}
              />
            </SimpleShowLayout>

            <Labeled label="E-marque">
              <TextField
                fontWeight="bold"
                source={"brandParent"}
              //label="E-marque"
              />
            </Labeled>
            <Labeled label="PTAC (Kg)">
              <NumberField
                fontWeight="bold"
                source={"ptac"}
              //label="PTAC (Kg)"
              />
            </Labeled>
            <Labeled label="PV (Kg)">
              <NumberField fontWeight="bold" source={"pv"} /*label="PV (Kg)"*/ />
            </Labeled>
            <SimpleShowLayout sx={{ padding: 0, fontSize: "20px" }}>
              <WithRecord
                //label="CU"
                render={(record) => (
                  <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                    {!record.ptac || !record.pv
                      ? " "
                      : numberWithCommas(record.ptac - record.pv)}
                  </span>
                )}
              />
            </SimpleShowLayout>
            {/* <Labeled label="CU">
          <NumberField fontWeight="bold" source={"cu"} label="CU" />
        </Labeled> */}
            <Labeled label="Essieu">
              <TextField fontWeight="bold" source={"axle"} /*label="Essieu"*/ />
            </Labeled>
            <Labeled label="Pneus">
              <TextField fontWeight="bold" source={"tires"} /*label="Pneus"*/ />
            </Labeled>
            <Labeled label="Réservoir carburant (L)">
              <NumberField
                fontWeight="bold"
                source={"tank"}
              //label="Réservoir carburant (L)"
              />
            </Labeled>
            <Labeled label="Puissance fiscale">
              <NumberField
                fontWeight="bold"
                source={"power"}
              //label="Puissance fiscale"
              />
            </Labeled>

            <Labeled label="Capacité (m³)">
              <NumberField
                fontWeight="bold"
                source={"capacity"}
              //label="Capacité (m³)"
              />
            </Labeled>
          </Box>
          <ImageField
            source="images"
            src="contentUrl"
            //label="Image"
            sx={{
              padding: "0",
            }}
          />
          <FileField
            source="images"
            src="contentUrl"
            title="ouvrir image"
            target="_blank"
            label={false}
            sx={{ listStyle: "none", display: "flex", paddingLeft: "0px", margin: "0px", gap: "220px" }}
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Administratif">
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
              xl: "repeat(5, 1fr)",
            }}
            gap={2}
            width="100%">
            <Labeled label="Propriétaire">
              <TextField
                fontWeight="bold"
                source={"administration.owner"}
              //label="Propriétaire"
              />
            </Labeled>
            <Labeled label="Contrat">
              <TextField
                fontWeight="bold"
                source={"administration.contract"}
              //label="Contrat"
              />
            </Labeled>
            <Labeled label="Exploitation">
              <TextField
                fontWeight="bold"
                source={"administration.operation"}
              //label="Exploitation"
              />
            </Labeled>
            <Labeled label="Type de contrat">
              <TextField
                fontWeight="bold"
                source={"administration.contractStatus"}
              //label="Type de contrat"
              />
            </Labeled>
            <Labeled label="Affectation analytique">
              <TextField
                fontWeight="bold"
                source={"administration.affectation"}
              //label="Affectation analytique"
              />
            </Labeled>
            <Labeled label="Date de mise en service">
              <DateField
                locales="fr-FR"
                fontWeight="bold"
                source={"administration.date"}
              //label="Date de mise en service"
              />
            </Labeled>
            <Labeled label="Statut">
              <TextField
                fontWeight="bold"
                source={"administration.status"}
              //label="Statut"
              />
            </Labeled>
          </Box>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Équipement">
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
              xl: "repeat(5, 1fr)",
            }}
            gap={2}
            width="100%">
            <Labeled label="Équipement de levage">
              <TextField
                fontWeight="bold"
                source={"equipment.washingEquipment"}
              //label="Équipement de levage"
              />
            </Labeled>
            <Labeled label="Crochet d'atellage">
              <TextField
                fontWeight="bold"
                source={"equipment.towingHitch"}
              //label="Crochet d'atellage"
              />
            </Labeled>
            <Labeled label="GPS">
              <BooleanField
                fontWeight="bold"
                source={"equipment.gps"}
              //label="GPS"
              />
            </Labeled>
          </Box>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Financier">
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
              xl: "repeat(5, 1fr)",
            }}
            gap={2}
            width="100%">
            <Labeled label="N° de contrat leasing">
              <TextField
                fontWeight="bold"
                source={"leasing.contractNumber"}
              //label="N° de contrat leasing"
              />
            </Labeled>
            <Labeled label="Organisme">
              <TextField
                fontWeight="bold"
                source={"leasing.organization"}
              //label="Organisme"
              />
            </Labeled>
            <Labeled label="Mensualité HT">
              <NumberField
                fontWeight="bold"
                source={"leasing.taxFreeMonthlyPayment"}
              //label="Mensualité HT"
              />
            </Labeled>
            <Labeled label="Durée">
              <NumberField
                fontWeight="bold"
                source={"leasing.duration"}
              //label="Durée"
              />
            </Labeled>
            <Labeled label="Début de contrat">
              <DateField
                locales="fr-FR"
                fontWeight="bold"
                source={"leasing.contractStartDate"}
              //label="Début de contrat"
              />
            </Labeled>
            <Labeled label="Fin de contrat">
              <DateField
                locales="fr-FR"
                fontWeight="bold"
                source={"leasing.contractEndDate"}
              //label="Fin de contrat"
              />
            </Labeled>
            <Labeled label="Valeur résiduelle">
              <NumberField
                fontWeight="bold"
                source={"leasing.vr"}
              //label="Valeur résiduelle"
              />
            </Labeled>
            <Labeled label="Prix d'acquisition">
              <NumberField
                fontWeight="bold"
                source={"leasing.acquisitionPrice"}
              //label="Prix d'acquisition"
              />
            </Labeled>
            <Labeled label="Montant du contrat">
              <NumberField
                fontWeight="bold"
                source={"leasing.contractAmount"}
              //label="Montant du contrat"
              />
            </Labeled>
            <Labeled label="État du contrat">
              <TextField
                fontWeight="bold"
                source={"leasing.status"}
              //label="État du contrat"
              />
            </Labeled>
          </Box>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Assurance">
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
              xl: "repeat(5, 1fr)",
            }}
            gap={2}
            width="100%">
            <Labeled label="Date">
              <DateField
                locales="fr-FR"
                fontWeight="bold"
                source={"insurance.date"}
              //label="Date"
              />
            </Labeled>
            <Labeled label="Nombre de places autorisées">
              <NumberField
                fontWeight="bold"
                source={"insurance.totalPlacesAllowed"}
              //label="Nombre de places autorisées"
              />
            </Labeled>
            <Labeled label="Nombre de sièges">
              <NumberField
                fontWeight="bold"
                source={"insurance.totalSeats"}
              //label="Nombre de sièges"
              />
            </Labeled>
            <Labeled label="Usage">
              <TextField
                fontWeight="bold"
                source={"insurance.usage"}
              //label="Usage"
              />
            </Labeled>
            <Labeled label="Puissance fiscale (CV)">
              <NumberField
                fontWeight="bold"
                source={"insurance.taxHorsePower"}
              //label="Puissance fiscale (CV)"
              />
            </Labeled>
            <Labeled label="DER">
              <NumberField
                fontWeight="bold"
                source={"insurance.der"}
              //label="DER"
              />
            </Labeled>
            <Labeled label="Valeur dommage">
              <NumberField
                fontWeight="bold"
                source={"insurance.damageValue"}
              //label="Valeur dommage"
              />
            </Labeled>
            <Labeled label="Valeur incendie">
              <NumberField
                fontWeight="bold"
                source={"insurance.fireValue"}
              //label="Valeur incendie"
              />
            </Labeled>
            <Labeled label="Valeur brise glace">
              <NumberField
                fontWeight="bold"
                source={"insurance.brokenWindowValue"}
              //label="Valeur brise glace"
              />
            </Labeled>
            <Labeled label="Prime RC">
              <NumberField
                fontWeight="bold"
                source={"insurance.rcBonus"}
              //label="Prime RC"
              />
            </Labeled>
            <Labeled label="Prime dommage">
              <NumberField
                fontWeight="bold"
                source={"insurance.damageBonus"}
              //label="Prime dommage"
              />
            </Labeled>
            <Labeled label="Prime incendie">
              <NumberField
                fontWeight="bold"
                source={"insurance.fireBonus"}
              //label="Prime incendie"
              />
            </Labeled>
            <Labeled label="Prime vol">
              <NumberField
                fontWeight="bold"
                source={"insurance.theftBonus"}
              //label="Prime vol"
              />
            </Labeled>
            <Labeled label="Prime brise glace">
              <NumberField
                fontWeight="bold"
                source={"insurance.brokenWindowBonus"}
              //label="Prime brise glace"
              />
            </Labeled>
            <Labeled label="Total (Net)">
              <NumberField
                fontWeight="bold"
                source={"insurance.totalValue"}
              //label="Total (Net)"
              />
            </Labeled>
          </Box>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Maintenance">
          {/* 
      <Typography variant="h6" fontWeight="fontWeightBold">
        Maintenance du vehicule
      </Typography> */}
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
              xl: "repeat(5, 1fr)",
            }}
            gap={2}
            width="100%">
            <Labeled label="Type de maintenance">
              <TextField
                fontWeight="bold"
                source={"maintenanceType"}
              //label="Type de maintenance"
              />
            </Labeled>
            {maintenanceType === "mileage" && (
              <>
                <NumberField
                  source={"lastMileage"}
                //label="Kilométrage de la dernière visite"
                />
                <NumberField
                  source={"mileageInterval"}
                //label="Intervale entre les visites (Km)"
                />
              </>
            )}
            {maintenanceType === "time" && (
              <>
                <NumberField
                  source={"lastTimeMeter"}
                //label="Compteur horaire de la dernière visite"
                />
                <NumberField
                  source={"timeInterval"}
                //label="Intervale entre les visites (heures)"
                />
              </>
            )}
          </Box>
        </TabbedShowLayout.Tab>

        {/* <TextField source={"images"} />
      <TextInput source={"site"} /> */}
      </TabbedShowLayout>
    </Show>
  );
};
