import * as React from "react";
import { DisponibilityLine } from "../components/charts/DisponibilityLine";
// import { shadows } from "@mui/system";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import PercentIcon from "@mui/icons-material/Percent";
import WarningIcon from "@mui/icons-material/Warning";
import { ListItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import axios from "axios";
import { Loading, SelectInput, SimpleForm, useAuthenticated } from "react-admin";
import { Link } from 'react-router-dom';
import { EssenceLine } from "../components/charts/EssenceLine";
import { GasoilLine } from "../components/charts/GasoilLine";
import { InterventionsTypeDoughnutCost } from "../components/charts/InterventionsTypeDoughnutCost";
import { InterventionsTypeDoughnutPercentage } from "../components/charts/InterventionsTypeDoughnutPercentage";
import numberWithCommas from "../components/numberWithCommas";
import entrypoint from "../config/params";
import { useTranslate } from 'react-admin';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface AvailabilityRate {
  currentMonth: number;
  lastMonth: number;
  currentDay: number;
}

interface InterventionRate {
  preventiveTotal: number;
  correctiveTotal: number;
  incidentTotal: number;
  preventivePercentage: number;
  correctivePercentage: number;
  incidentPercentage: number;
  total: number;
}

interface Rate {
  availabilities: AvailabilityRate;
  interventions: InterventionRate;
}

interface Alert {
  regulatoryControl: number;
  preventiveMaintenance: number;
  taxes: number;
  insurances: number;
  fuelAllocation: number;
  gasoilAllocations: number;
  essenceAllocations: number;
  fuelConsumption: number;
  pendingImmobilizations: number;
  urgentImmobilizations: number;
  currentImmobilizations: number;
  todoControl: number;
  expiredControl: number;
  todoInsurance: number;
  expiredInsurance: number;
  todoTax: number;
  expiredTax: number;
  todoDrain: number;
  expiredDrain: number;
}

interface FuelDistribution {
  date: Date;
  value: number;
}

interface Availability {
  date: Date;
  value: number;
}

interface Stat {
  currentTotalImmobilizations: number;
  urgentTotalImmobilizations: number;
  pendingTotalImmobilizations: number;
  dieselDistributions: Array<FuelDistribution>;
  gasDistributions: Array<FuelDistribution>;
  dieselPriceDistributions: Array<FuelDistribution>;
  gasPriceDistributions: Array<FuelDistribution>;
  availabilities: Array<Availability>;
}

interface DashboardStat {
  rates: Rate;
  alerts: Alert;
  stats: Stat;
}

const Dashboard = () => {
  const translate = useTranslate();
  useAuthenticated();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<DashboardStat | undefined>(undefined);
  const currYear = new Date().getFullYear();
  const currMonth = new Date().getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = React.useState(String(currMonth));
  const [selectedYear, setSelectedYear] = React.useState(String(currYear));

  const today = new Date();

  // Extract year, month, and day components
  const year = String(today.getFullYear());
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
  const day = String(today.getDate()).padStart(2, '0');

  // Concatenate components in the desired format
  const formattedDate = `${year}-${month}-${day}`;

  const fetchData = async (month: string, year: string) => {
    const token = localStorage.getItem("token");
    if (token === null) {
      return null;
    }
    const { data } = await axios.get<DashboardStat>(
      `${entrypoint}/dashboard-stats`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: { month, year }, // Pass selected month as a query parameter
      }
    );
    console.log(data);
    console.log(month)
    console.log(year)
    console.log(typeof month)
    setData(data);
    setLoading(false);
  };


  React.useEffect(() => {
    fetchData(selectedMonth, selectedYear);
  }, [selectedMonth, selectedYear]);

  //console.log("data-stats", data);

  if (loading) {
    return <Loading />;
  }

  if (data) {
    const lastMonth = data?.rates?.availabilities?.lastMonth;
    const currentMonth = data?.rates?.availabilities?.currentMonth;
    const currentDay = data?.rates?.availabilities?.currentDay;
    const pendingImmobilizations = data?.alerts?.pendingImmobilizations;
    const urgentImmobilizations = data?.alerts?.urgentImmobilizations;
    const currentImmobilizations = data?.alerts?.currentImmobilizations;
    const todoControl = data?.alerts?.todoControl;
    const expiredControl = data?.alerts?.expiredControl;
    const todoInsurance = data?.alerts?.todoInsurance;
    const expiredInsurance = data?.alerts?.expiredInsurance;
    const todoTax = data?.alerts?.todoTax;
    const expiredTax = data?.alerts?.expiredTax;
    const todoDrain = data?.alerts?.todoDrain;
    const expiredDrain = data?.alerts?.expiredDrain;

    return (
      <Box>
        <Box sx={{ display: "flex" }} gap="24px" margin="24px">
          <Box
            sx={{
              width: "67%",
              bgcolor: "background.paper",
              boxShadow: 2,
              borderRadius: "12px",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center", my: "8px" }}>
              {translate('titles.tauxDisponibilite')}
            </Typography>


            <Box sx={{ display: "flex", justifyContent: 'end', alignItems: 'center', gap: '8px', p: '8px 16px'}}>
              <Select
                labelId="select-label"
                id="select"
                value={selectedMonth}
                onChange={(event) => setSelectedMonth(event.target.value)}
              >
                <MenuItem value={1}>{translate('labels.month1')}</MenuItem>
                <MenuItem value={2}>{translate('labels.month2')}</MenuItem>
                <MenuItem value={3}>{translate('labels.month3')}</MenuItem>
                <MenuItem value={4}>{translate('labels.month4')}</MenuItem>
                <MenuItem value={5}>{translate('labels.month5')}</MenuItem>
                <MenuItem value={6}>{translate('labels.month6')}</MenuItem>
                <MenuItem value={7}>{translate('labels.month7')}</MenuItem>
                <MenuItem value={8}>{translate('labels.month8')}</MenuItem>
                <MenuItem value={9}>{translate('labels.month9')}</MenuItem>
                <MenuItem value={10}>{translate('labels.month10')}</MenuItem>
                <MenuItem value={11}>{translate('labels.month11')}</MenuItem>
                <MenuItem value={12}>{translate('labels.month12')}</MenuItem>
              </Select>
              <Select
                labelId="select-label"
                id="select"
                value={selectedYear}
                onChange={(event) => setSelectedYear(event.target.value)}
              >
                <MenuItem value={2024}>2024</MenuItem>
                <MenuItem value={2025}>2025</MenuItem>
              </Select>
            </Box>

            <List>
              <ListItem>
                <ListItemIcon>
                  <PercentIcon sx={{ color: "#222" }} />
                </ListItemIcon>
                <ListItemText primary={translate('listItems.tauxDisponibiliteMoisDernier')} />
                <Chip
                  label={`${lastMonth}%`}
                  sx={{
                    backgroundColor: lastMonth > 90 ? "#e8f5e9" : "#ffebee",
                    color: lastMonth > 90 ? "green" : "red",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PercentIcon style={{ color: "#222" }} />
                </ListItemIcon>
                <ListItemText primary={translate('listItems.tauxDisponibiliteMoisCourant')} />
                <Chip
                  label={`${currentMonth}%`}
                  sx={{
                    backgroundColor: currentMonth > 90 ? "#e8f5e9" : "#ffebee",
                    color: currentMonth > 90 ? "green" : "red",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PercentIcon style={{ color: "#222" }} />
                </ListItemIcon>
                <ListItemText primary={translate('listItems.tauxDisponibiliteJournalier')} />
                <Chip
                  label={`${currentDay}%`}
                  sx={{
                    backgroundColor: currentDay > 90 ? "#e8f5e9" : "#ffebee",
                    color: currentDay > 90 ? "green" : "red",
                  }}
                />
              </ListItem>
            </List>
            <DisponibilityLine data={data.stats?.availabilities} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "33%",
              bgcolor: "background.paper",
              boxShadow: 2,
              borderRadius: "12px",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center", my: "8px" }}>
              {translate('titles.typeInterventions')}
            </Typography>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <Box sx={{ margin: "auto" }}>
                <InterventionsTypeDoughnutCost
                  data={data?.rates?.interventions}
                />
                <InterventionsTypeDoughnutPercentage
                  data={data?.rates?.interventions}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        {/* <Box sx={{ display: "flex" }} gap="24px" margin="24px">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              width: "100%",

              bgcolor: "background.paper",
              boxShadow: 2,
              borderRadius: "12px",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center", mt: "8px" }}>
              Alertes techniques
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon sx={{ color: "green" }} />
                </ListItemIcon>
                <ListItemText primary="Contrôle réglementaire" />
                <Chip
                  label={data?.alerts?.regulatoryControl}
                  sx={{ backgroundColor: "#e8f5e9", color: "green" }}
                />
              </ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <WarningIcon style={{ color: "orange" }} />
                </ListItemIcon>
                <ListItemText primary="Maintenances préventives" />
                <Chip
                  label={data?.alerts?.preventiveMaintenance}
                  sx={{ backgroundColor: "#ffebee", color: "red" }}
                />
              </ListItemButton>
            </List>
          </Box>
        </Box> */}

        <Box sx={{ display: "flex" }} gap="24px" margin="24px">
          <Box
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              boxShadow: 2,
              borderRadius: "12px",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center", mt: "8px" }}>
              {translate('titles.immobilisationsJour')}
            </Typography>
            <List>
              <ListItemButton component={Link}
                to={{
                  pathname: '/vehicle-immobility-histories',
                  search: `filter=${JSON.stringify({ status: 'En attente', date: formattedDate })}`,
                }}>
                <ListItemIcon>
                  {pendingImmobilizations === 0 ? (
                    <CheckCircleOutlineIcon sx={{ color: "green" }} />
                  ) : (
                    <WarningIcon style={{ color: "orange" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={translate('listItems.enAttente')} />
                <Chip
                  label={data?.alerts?.pendingImmobilizations}
                  sx={{
                    backgroundColor:
                      pendingImmobilizations === 0 ? "#e8f5e9" : "#ffebee",
                    color: pendingImmobilizations === 0 ? "green" : "red",
                  }}
                />
              </ListItemButton>
              <ListItemButton component={Link}
                to={{
                  pathname: '/vehicle-immobility-histories',
                  search: `filter=${JSON.stringify({ status: 'En urgence', date: formattedDate })}`,
                }}>
                <ListItemIcon>
                  {urgentImmobilizations === 0 ? (
                    <CheckCircleOutlineIcon sx={{ color: "green" }} />
                  ) : (
                    <WarningIcon style={{ color: "orange" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={translate('listItems.enUrgence')} />
                <Chip
                  label={data?.alerts?.urgentImmobilizations}
                  sx={{
                    backgroundColor:
                      urgentImmobilizations === 0 ? "#e8f5e9" : "#ffebee",
                    color: urgentImmobilizations === 0 ? "green" : "red",
                  }}
                />
              </ListItemButton>
              <ListItemButton component={Link}
                to={{
                  pathname: '/vehicle-immobility-histories',
                  search: `filter=${JSON.stringify({ status: 'En cours', date: formattedDate })}`,
                }}>
                <ListItemIcon>
                  {currentImmobilizations === 0 ? (
                    <CheckCircleOutlineIcon sx={{ color: "green" }} />
                  ) : (
                    <WarningIcon style={{ color: "orange" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={translate('listItems.enCours')} />
                <Chip
                  label={data?.alerts?.currentImmobilizations}
                  sx={{
                    backgroundColor:
                      currentImmobilizations === 0 ? "#e8f5e9" : "#ffebee",
                    color: currentImmobilizations === 0 ? "green" : "red",
                  }}
                />
              </ListItemButton>
            </List>
          </Box>
          <Box
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              boxShadow: 2,
              borderRadius: "12px",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center", mt: "8px" }}>
              {translate('titles.alertesCarburant')}
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <LocalGasStationIcon sx={{ color: "#222" }} />
                </ListItemIcon>
                <ListItemText primary={translate('listItems.totalApprovisionnementGasoil')} />
                <Chip
                  label={`${numberWithCommas(
                    data?.alerts?.gasoilAllocations
                  )} L`}
                // sx={{ backgroundColor: "#e8f5e9", color: "green" }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LocalGasStationIcon sx={{ color: "#222" }} />
                </ListItemIcon>
                <ListItemText primary={translate('listItems.totalApprovisionnementEssence')} />
                <Chip
                  label={`${numberWithCommas(
                    data?.alerts?.essenceAllocations
                  )} L`}
                // sx={{ backgroundColor: "#e8f5e9", color: "green" }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {data?.alerts?.fuelConsumption === 0 ? (
                    <CheckCircleOutlineIcon sx={{ color: "green" }} />
                  ) : (
                    <WarningIcon style={{ color: "orange" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={translate('listItems.alerteConsommation')} />
                <Chip
                  label={data?.alerts?.fuelConsumption}
                  sx={{
                    backgroundColor:
                      data?.alerts?.fuelConsumption === 0
                        ? "#e8f5e9"
                        : "#ffebee",
                    color:
                      data?.alerts?.fuelConsumption === 0 ? "green" : "red",
                  }}
                />
              </ListItem>
            </List>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }} gap="24px" margin="24px">
          <Box
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              boxShadow: 2,
              borderRadius: "12px",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center", mt: "8px" }}>
              {translate('titles.alertesControles')}
            </Typography>
            <List>
              <ListItemButton component={Link}
                to={{
                  pathname: '/vehicle-control-histories',
                  search: `filter=${JSON.stringify({ status: 'todo' })}`,
                }}>
                <ListItemIcon>
                  {todoControl === 0 ? (
                    <CheckCircleOutlineIcon sx={{ color: "green" }} />
                  ) : (
                    <WarningIcon style={{ color: "orange" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={translate('listItems.aVenir')} />
                <Chip
                  label={data?.alerts?.todoControl}
                  sx={{
                    backgroundColor:
                      todoControl === 0 ? "#e8f5e9" : "#fff8e1",
                    color: todoControl === 0 ? "green" : "#ff8f00",
                  }}
                />
              </ListItemButton>
              <ListItemButton component={Link}
                to={{
                  pathname: '/vehicle-control-histories',
                  search: `filter=${JSON.stringify({ status: 'expired' })}`,
                }}>
                <ListItemIcon>
                  {expiredControl === 0 ? (
                    <CheckCircleOutlineIcon sx={{ color: "green" }} />
                  ) : (
                    <ErrorIcon style={{ color: "red" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={translate('listItems.enRetard')} />
                <Chip
                  label={data?.alerts?.expiredControl}
                  sx={{
                    backgroundColor:
                      expiredControl === 0 ? "#e8f5e9" : "#ffebee",
                    color: expiredControl === 0 ? "green" : "red",
                  }}
                />
              </ListItemButton>
            </List>
          </Box>
          <Box
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              boxShadow: 2,
              borderRadius: "12px",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center", mt: "8px" }}>
              {translate('titles.alertesAssurances')}
            </Typography>
            <List>
              <ListItemButton component={Link}
                to={{
                  pathname: '/vehicle-insurance-histories',
                  search: `filter=${JSON.stringify({ status: 'todo' })}`,
                }}>
                <ListItemIcon>
                  {todoInsurance === 0 ? (
                    <CheckCircleOutlineIcon sx={{ color: "green" }} />
                  ) : (
                    <WarningIcon style={{ color: "orange" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={translate('listItems.aVenir')} />
                <Chip
                  label={data?.alerts?.todoInsurance}
                  sx={{
                    backgroundColor:
                      todoInsurance === 0 ? "#e8f5e9" : "#fff8e1",
                    color: todoInsurance === 0 ? "green" : "#ff8f00",
                  }}
                />
              </ListItemButton>
              <ListItemButton component={Link}
                to={{
                  pathname: '/vehicle-insurance-histories',
                  search: `filter=${JSON.stringify({ status: 'expired' })}`,
                }}>
                <ListItemIcon>
                  {expiredInsurance === 0 ? (
                    <CheckCircleOutlineIcon sx={{ color: "green" }} />
                  ) : (
                    <ErrorIcon style={{ color: "red" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={translate('listItems.enRetard')} />
                <Chip
                  label={data?.alerts?.expiredInsurance}
                  sx={{
                    backgroundColor:
                      expiredInsurance === 0 ? "#e8f5e9" : "#ffebee",
                    color: expiredInsurance === 0 ? "green" : "red",
                  }}
                />
              </ListItemButton>
            </List>
          </Box>
          <Box
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              boxShadow: 2,
              borderRadius: "12px",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center", mt: "8px" }}>
              {translate('titles.alertesTaxesVignettes')}
            </Typography>
            <List>
              <ListItemButton component={Link}
                to={{
                  pathname: '/vehicle-tax-histories',
                  search: `filter=${JSON.stringify({ status: 'todo' })}`,
                }}>
                <ListItemIcon>
                  {todoTax === 0 ? (
                    <CheckCircleOutlineIcon sx={{ color: "green" }} />
                  ) : (
                    <WarningIcon style={{ color: "orange" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={translate('listItems.aVenir')} />
                <Chip
                  label={data?.alerts?.todoTax}
                  sx={{
                    backgroundColor:
                      todoTax === 0 ? "#e8f5e9" : "#fff8e1",
                    color: todoTax === 0 ? "green" : "#ff8f00",
                  }}
                />
              </ListItemButton>
              <ListItemButton component={Link}
                to={{
                  pathname: '/vehicle-tax-histories',
                  search: `filter=${JSON.stringify({ status: 'expired' })}`,
                }}>
                <ListItemIcon>
                  {expiredTax === 0 ? (
                    <CheckCircleOutlineIcon sx={{ color: "green" }} />
                  ) : (
                    <ErrorIcon style={{ color: "red" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={translate('listItems.enRetard')} />
                <Chip
                  label={data?.alerts?.expiredTax}
                  sx={{
                    backgroundColor:
                      expiredTax === 0 ? "#e8f5e9" : "#ffebee",
                    color: expiredTax === 0 ? "green" : "red",
                  }}
                />
              </ListItemButton>
            </List>
          </Box>
          <Box
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              boxShadow: 2,
              borderRadius: "12px",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center", mt: "8px" }}>
              Alertes vidanges
            </Typography>
            <List>
              <ListItemButton component={Link}
                to={{
                  pathname: '/vehicle-drain-histories',
                  search: `filter=${JSON.stringify({ status: 'todo' })}`,
                }}>
                <ListItemIcon>
                  {todoTax === 0 ? (
                    <CheckCircleOutlineIcon sx={{ color: "green" }} />
                  ) : (
                    <WarningIcon style={{ color: "orange" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary="À venir" />
                <Chip
                  label={data?.alerts?.todoDrain}
                  sx={{
                    backgroundColor:
                      todoDrain === 0 ? "#e8f5e9" : "#fff8e1",
                    color: todoDrain === 0 ? "green" : "#ff8f00",
                  }}
                />
              </ListItemButton>
              <ListItemButton component={Link}
                to={{
                  pathname: '/vehicle-drain-histories',
                  search: `filter=${JSON.stringify({ status: 'expired' })}`,
                }}>
                <ListItemIcon>
                  {expiredDrain === 0 ? (
                    <CheckCircleOutlineIcon sx={{ color: "green" }} />
                  ) : (
                    <ErrorIcon style={{ color: "red" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={translate('listItems.enRetard')} />
                <Chip
                  label={data?.alerts?.expiredDrain}
                  sx={{
                    backgroundColor:
                      expiredDrain === 0 ? "#e8f5e9" : "#ffebee",
                    color: expiredDrain === 0 ? "green" : "red",
                  }}
                />
              </ListItemButton>
            </List>
          </Box>

        </Box>
        <Box sx={{ display: "flex" }} gap="24px" margin="24px">
          <Box
            sx={{
              width: "100%",

              bgcolor: "background.paper",
              boxShadow: 2,
              borderRadius: "12px",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center", mt: "8px" }}>
              {translate('titles.consommationGasoil')}
            </Typography>
            <GasoilLine data1={data.stats?.dieselDistributions} data2={data.stats?.dieselPriceDistributions} />
          </Box>
          <Box
            sx={{
              width: "100%",

              bgcolor: "background.paper",
              boxShadow: 2,
              borderRadius: "12px",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center", mt: "8px" }}>
              {translate('titles.consommationEssence')}
            </Typography>

            <EssenceLine data1={data.stats?.gasDistributions} data2={data.stats?.gasPriceDistributions} />
          </Box>
        </Box>
      </Box>
    );
  }
  return null;
};

export default Dashboard;
